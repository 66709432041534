@font-face {
  font-family: 'Fira Code Nerd Font';
  src: url('FiraCodeNerdFontMono-Regular.ttf') format('truetype');
}

body {
  font-family: 'Fira Code Nerd Font', monospace;
}

body {
  font: 0.875em/1.6 "Inconsolata", monospace;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
}
#root {
  padding-left: 5px;
}

input[type="text"],
input[type="text"]:focus {
  width: 50%;
  border: none;
  font-family: inherit;
  background: #282c34;
  padding: 0 0.5em;
  color: white;
  outline: none;
}
.command {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  color: white;
  font-weight: 700;
}

.command input {
  flex: 1;
}

kbd {
  font-family: "Inconsolata", monospace;
  border: 1px solid #999;
  text-transform: uppercase;
  padding: 0 0.2em;
}

a {
  color: #969696;
}
a:hover,
a:focus {
  color: #fff;
}

abbr {
  cursor: help;
}
ul {
  list-style: none;
  padding: 0;
}
li {
  margin: 0.5em 0 0;
}

.inline {
  display: inline-block;
}

#table {
  animation-timing-function: linear;
  animation: table-flip 2s infinite;
  position: relative;
}

.arm {
  animation-timing-function: linear;
  animation: arm-rotate 2s infinite;
  position: relative;
}

.error {
  color: #c36c38;
}

@keyframes table-flip {
  0% {
    left: 0px;
    top: 0em;
    transform: rotate(-180deg);
  }
  24% {
    top: -2em;
  }
  60% {
    left: 2.5em;
    top: 0em;
    transform: rotate(0deg);
  }
  80% {
    left: 2.5em;
    top: 0em;
    transform: rotate(0deg);
  }
  100% {
    left: 0px;
    top: 0em;
    transform: rotate(-180deg);
  }
}

@keyframes arm-rotate {
  0% {
    top: 0.2em;
    transform: rotate(90deg);
  }
  20% {
    top: 0em;
    transform: rotate(0deg);
  }
  50% {
    top: 0em;
    transform: rotate(0deg);
  }
  100% {
    top: 0.2em;
    transform: rotate(90deg);
  }
}


.skills-area span{
  font-weight: 900;
color:#c36c38;
}

.cmd-machine-name{
  position: relative;
  background: #c37838;
  color: black;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 50px 0 0 50px;
}

.cmd-machine-name::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px; /* Adjust this value to position the arrow correctly */
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 10px solid #c37838; /* Color of the arrow, which is the background color of the right span */
  transform: translateY(-50%);
}

.cmd-path {
  background: #3170c8;
  padding-left: 1em;
  padding-right: 0.5em;
  border-radius: 0 50px 50px 0;
}

/* Add these styles to your CSS file */
.panelVisible {
  animation: jiggle 0.5s ease-in-out;
}

@keyframes jiggle {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}
